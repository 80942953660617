import { ContentTypeEnum } from '@/enums/http-enum'

/**
 * 查询平台实时事件
 */
export const queryPlatformRuntimeEventsApi = (params: RuntimeEventParam) => useGet<RuntimePlatformEvent>('/runtime/platform-event/list', params)

/**
 * 查询平台历史事件
 */
export const queryPlatformHistoryEventApi = (params: HistoryEventParam) => useGet<Paging<HistoryPlatformEvent>>('/runtime/history-platform-event/page', params)

/**
 * 处理平台实时事件
 */
export const confirmRuntimeEventApi = (params: ConfirmEventParam) => usePost<boolean>('/runtime/platform-event/confirm', params, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 按设备查询平台事件列表
 */
export const queryPlatformEventsApi = (deviceCode: string) => useGet('/deploy/platform-event/list', { deviceCode })

/**
 * 新增平台事件
 */
export const addPlatformEventApi = (platEvent: any) => usePost('/deploy/platform-event', platEvent)

/**
 * 修改平台事件
 */
export const updatePlatformEventApi = (platEvent: any) => usePut('/deploy/platform-event', platEvent)

/**
 * 删除平台事件
 */
export const deletePlatformEventApi = (id: number) => useDelete(`/deploy/platform-event/${id}`)

/**
 * 拷贝表达式到某个设备
 */
export const copyExpressionToDeviceApi = (params: any) => usePost('/deploy/platform-event/copy-from-model-to-device', params, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 * 拷贝表达式到某个模型下的所有设备
 */
export const copyExpressionToAllDevicesApi = (params: any) => usePost('/deploy/platform-event/copy-from-model-to-all-devices', params, { headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED } })

/**
 *  统计
 */
export const getPlatformEventCountsApi = () => useGet('/runtime/platform-event/count')
